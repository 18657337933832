import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import { Section, BreadCrumb, SectionTitle, Container, LeftPannel, RightPannel, MarkdownContent, SectionDescription } from "../components/Section"
import { HeroSection, HeroCover, LeftHero, RightHero, HeroPageTitle, HeroDesc, HeroFormWrap, HeroFormTitle, HeroFormBody } from "../components/Hero"
import CallToAction from "../components/CallToAction"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"
import MetalBuildingSizeOne from "../images/metal-building-28X70.jpg"
import MetalBuildingSizeTwo from "../images/metal-building-30X70.jpg"
import MetalBuildingSizeThree from "../images/metal-building-36X26.jpg"
import QuickContactForm from "../components/QuickContactForm"

const Size = styled.ul`
	list-style:none;
	margin:0;
	padding:0;
`
const SizeItem = styled.li`
	display: flex;
    flex-wrap: wrap;
	margin:0 -15px;	
	@media(min-width: 1200px) {		
		margin:0 -30px;		
	}
	+ li{
		margin-top:30px;
		@media(min-width: 1200px) {		
			margin-top:60px;
		}
	}

`
const SizeLeftPanel = styled.div`
	position: relative;
    width: 100%;
	padding:0 15px;
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
	@media(min-width: 1200px) {	
    	padding:0 30px;
	}
`
const SizeRightPanel = styled.div`
	position: relative;
    width: 100%;
	flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding:0 15px;
	@media(min-width: 992 px) {	

	}
	@media(min-width: 1200px) {	
    	padding:0 30px;
	}
`
const SizeTitle = styled.h3`
	position:relative;
	display: inline-block;
	&:before{
		content: '';
		position:absolute;
		bottom:0;
		left:0;
		right:0;
		height:2px;
		background-color:#ED602B;
	}

`
const SizeDescription = styled.div`

`

const  MetalBuildings = ({ location}) => {
	return (
		<Layout location={location}>
			<SEO title="Metal Buildings" description="Metal Buildings" />
			<HeroSection>
				<div className="container">
					<BreadCrumb className="static"><Link to="/">Home</Link> / <span> Metal Buildings</span></BreadCrumb>
					<HeroCover>
						<LeftHero>
							<HeroPageTitle>Metal Buildings</HeroPageTitle>
							<HeroDesc>
							<p>No matter where in the US you call home, you can bet that metal buildings and steel-built structures are quickly becoming one of the most popular construction methods on the market. It doesn’t matter whether it’s a small lawn equipment shed or an enormous industrial compound; metal buildings can be built quicker, cheaper, and last longer than traditional stick-built structures.</p>
							<p>Custom metal buildings are not only one of the strongest building types, but they’re also incredibly versatile. This is precisely why you’ll see them used in almost every corner of the economy, from private residential use to full-sized commercial establishments. So whether you're looking for a new RV carport or large barn for your horses, you've come to the right place!</p>
							</HeroDesc>
						</LeftHero>
						<RightHero>
							<HeroFormWrap>
								<HeroFormTitle>Get In Touch</HeroFormTitle>
								<HeroFormBody><QuickContactForm location={location} formName="Metal Buildings Contact Form" /></HeroFormBody>
							</HeroFormWrap>
						</RightHero>
					</HeroCover>
				</div>
			</HeroSection>
			<CallToAction />
			<Section pt="90px" pb="90px" bg="#F4FBFF">
				<Container>
					<SectionTitle maxWidth="780px">Types Of Metal Buildings We Offer At Probuilt Steel Buildings</SectionTitle>
					<SectionDescription><p>Because our buildings are so adaptable, they can come in many different forms. To help you decide exactly what type of structure you need, we've put together a comprehensive list of the other structure types:</p></SectionDescription>
				</Container>
			</Section>
			<Section pt="90px" pb="90px" bg="#fff">
				<Container maxWidth="1120px">
					<SectionTitle>Popular Sizes Of Metal Buildings</SectionTitle>
					<SectionDescription mb="40px"><p>No matter what building type you choose, it's important to find the right size to fit your needs. To help you make sense of the different dimension options, we’ve compiled a few of our more popular size options below and some of their best uses:</p></SectionDescription>
					<Size>
						<SizeItem>
							<SizeLeftPanel>
								<img src={MetalBuildingSizeOne} alt="metal-building-28X70" />
							</SizeLeftPanel>
							<SizeRightPanel>
								<SizeTitle>28X70</SizeTitle>
								<SizeDescription><p>A 28x70 metal building offers nearly 2000 ft of available square footage. That’s more than a mid-sized house! You’ll never run out of room with a building of this size, but if you need something larger, you’ve come to the right place!</p></SizeDescription>
							</SizeRightPanel>
						</SizeItem>
						<SizeItem>
							<SizeLeftPanel>
								<img src={MetalBuildingSizeTwo} alt="metal-building-30X70" />
							</SizeLeftPanel>
							<SizeRightPanel>
								<SizeTitle>30X70</SizeTitle>
								<SizeDescription><p>30x70 metal buildings are large enough to accommodate numerous vehicles or larger equipment. It’s even large enough to house a semi! If you’re hoping to build a repair center or need a place to store your work truck, a 30x70 building may be just right for you!</p></SizeDescription>
							</SizeRightPanel>
						</SizeItem>
						<SizeItem>
							<SizeLeftPanel>
								<img src={MetalBuildingSizeThree} alt="metal-building-36X26" />
							</SizeLeftPanel>
							<SizeRightPanel>
								<SizeTitle>36X26</SizeTitle>
								<SizeDescription><p>This building is excellent for storing a couple of smaller vehicles and would make a perfect place for a workshop!</p></SizeDescription>
							</SizeRightPanel>
						</SizeItem>
					</Size>
				</Container>
			</Section>
			<Section pt="90px" pb="90px" bg="#F4FBFF">
				<Container>
					<SectionTitle>Fully-Customizable Steel Buildings</SectionTitle>
					<SectionDescription><p>No matter what building size and type you need, Probuilt Steel Buildings has you covered! We offer an extensive array of customization options, allowing you to design and change almost every facet of your new building. Don’t believe us? Take a look a just a few of the customization choices available:</p></SectionDescription>
					<LeftPannel>
						<MarkdownContent>
							<h2>Building Styles</h2>
							<p>The style of building you choose is entirely up to you! Easily adjust the style to suit your needs. We have plenty of options, from more modern design choices to more traditional home-like structures.</p>
							
							<h2>Anchors</h2>
							<p>Anchors are pieces of hardware that keep your metal building attached to the ground. Depending on your choice of foundation, you’ll have several anchor options available to you.  These include:</p>

							<h3>Rebar Anchors</h3>
							<p>This anchor type is designed to secure buildings to the soil in areas with low winds. They offer a tremendous amount of stability, but if you live in an area that sees nasty weather, you’ll need more heavy-duty options.</p>
							
							<h3>Mobile Home Anchors</h3>
							<p>Mobile home anchors are more sturdy than rebar anchor systems and commonly secure mobile homes to the ground. With an auger-like corkscrew design, these anchors will stay firmly planted in the ground.</p>
							
							<h3>Concrete Anchors</h3>
							<p>This system adheres buildings to concrete foundations using drilled holes and thick ‘bolt’ anchors. Concrete anchors are an incredibly stable choice that will ensure your building stands up to almost any weather.</p>

							<h3>Asphalt Anchors</h3>
							<p>Asphalt anchors use special barbed anchors to connect your building to an asphalt foundation. This anchor system is a stable option, but you may choose to go with a concrete foundation if you worry about severe weather.</p>

							<h2>Other Customization Options</h2>

							<h3>Doors And Windows</h3>
							<p>We have a large assortment of doors and windows to choose from to match any need, including premium windows and oversized garage doors. And if we don’t have the window type you need, we can always frame it out so you can easily install it yourself later!</p>
							
							<h3>Colors</h3>
							<p>Adding a splash of color to your new building has never been easier! We have a large assortment of colors for our panels, trim, and even wainscoting, giving you the ability to customize your building’s aesthetics. Additionally, you can improve your building’s heating efficiency by picking lighter colors in hotter regions and darker tones for cooler climates.</p>

							<h3>Gauges</h3>
							<p>With our metal buildings, you have a choice between 29 and 26 gauge steel panels. Both paneling thicknesses can stand up to harsh weather, but 26 gauge paneling is thicker and far more durable if you live in areas that often see heavy winds and extreme snowfall.</p>

							<h3>Steel Framing</h3>
							<p>Our buildings come standard with 14 gauge frames that are durable and capable of standing up to some pretty nasty environmental forces. However, if you live in an area that experiences heavy wind, rain, and snowfall, you might want to choose our thicker 12 gauge framing reinforcements.</p>

						</MarkdownContent>
					</LeftPannel>
					<RightPannel>
						<DesignPlan />
						<AccreditedBusiness />
					</RightPannel>
				</Container>
			</Section>
			
		</Layout>
	)
}

export default MetalBuildings